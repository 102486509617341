export const BASIC_MONTHLY_PRICE = 15.9;
export const BASIC_YEARLY_PRICE = 12.9;
export const BASIC_KIDS_MONTHLY_PRICE = BASIC_MONTHLY_PRICE;
export const BASIC_KIDS_YEARLY_PRICE = BASIC_YEARLY_PRICE;
export const PLUS_MONTHLY_PRICE = 25.9;
export const PLUS_YEARLY_PRICE = 22.9;
export const PLUS_KIDS_MONTHLY_PRICE = PLUS_MONTHLY_PRICE;
export const PLUS_KIDS_YEARLY_PRICE = PLUS_YEARLY_PRICE;
export const RUNDUM_YEARLY_PRICE = 29.9;
export const RUNDUM_MONTHLY_PRICE = 34.9;
export const RUNDUM_PLUS_YEARLY_PRICE = 39.9;
export const RUNDUM_PLUS_MONTHLY_PRICE = 44.9;
