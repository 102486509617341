import {
  BASIC_KIDS_MONTHLY_PRICE,
  BASIC_KIDS_YEARLY_PRICE,
  BASIC_MONTHLY_PRICE,
  BASIC_YEARLY_PRICE,
  PLUS_KIDS_MONTHLY_PRICE,
  PLUS_KIDS_YEARLY_PRICE,
  PLUS_MONTHLY_PRICE,
  PLUS_YEARLY_PRICE,
  RUNDUM_MONTHLY_PRICE,
  RUNDUM_PLUS_MONTHLY_PRICE,
  RUNDUM_PLUS_YEARLY_PRICE,
  RUNDUM_YEARLY_PRICE,
} from "~/constants/membership";
import { Membership } from "~/utils/enum/Membership";

function getChargebeeMembershipType(membership: Membership, type: "monthly" | "yearly" = "yearly"): string {
  switch (membership) {
    case Membership.BASIC:
      return type === "monthly" ? "Basic-EUR-Monthly" : "Basic-EUR-Yearly";
    case Membership.BASIC_KIDS:
      return type === "monthly" ? "Kids-Basic-EUR-Monthly" : "Kids-Basic-EUR-Yearly";
    case Membership.PLUS:
      return type === "monthly" ? "Plus-EUR-Monthly" : "Plus-EUR-Yearly";
    case Membership.PLUS_KIDS:
      return type === "monthly" ? "Kids-Plus-EUR-Monthly" : "Kids-Plus-EUR-Yearly";
    case Membership.RUNDUM:
      return type === "monthly" ? "rundum-trial-EUR-Monthly" : "rundum-trial-EUR-Yearly";
    case Membership.RUNDUM_PLUS:
      return type === "monthly" ? "Rundum-Plus-trial-EUR-Monthly" : "Rundum-Plus-trial-EUR-Yearly";
  }
}

export function getMembershipPrice(membership: Membership, type: "monthly" | "yearly" = "yearly"): number {
  switch (membership) {
    case Membership.BASIC:
      return type === "monthly" ? BASIC_MONTHLY_PRICE : BASIC_YEARLY_PRICE;
    case Membership.BASIC_KIDS:
      return type === "monthly" ? BASIC_KIDS_MONTHLY_PRICE : BASIC_KIDS_YEARLY_PRICE;
    case Membership.PLUS:
      return type === "monthly" ? PLUS_MONTHLY_PRICE : PLUS_YEARLY_PRICE;
    case Membership.PLUS_KIDS:
      return type === "monthly" ? PLUS_KIDS_MONTHLY_PRICE : PLUS_KIDS_YEARLY_PRICE;
    case Membership.RUNDUM:
      return type === "monthly" ? RUNDUM_MONTHLY_PRICE : RUNDUM_YEARLY_PRICE;
    case Membership.RUNDUM_PLUS:
      return type === "monthly" ? RUNDUM_PLUS_MONTHLY_PRICE : RUNDUM_PLUS_YEARLY_PRICE;
  }
}

export function createMembershipLink(
  membership: Membership,
  type: "monthly" | "yearly" = "yearly",
  trackingParameter?: string,
): string {
  const urlParams = new URLSearchParams("subscription_items[quantity][0]=1&layout=in_app");
  urlParams.set("subscription_items[item_price_id][0]", getChargebeeMembershipType(membership, type));

  if (trackingParameter !== undefined) {
    urlParams.set("customer[cf_source]", trackingParameter);
  }

  return `https://haelsi.chargebee.com/hosted_pages/checkout?${urlParams.toString()}`;
}
